.modalSplash {
  & > div {
    background-color: rgb(0 0 0 / 36%) !important;
    & > div {
        background-color: transparent !important;
        align-items: center;
        justify-content: center;
      & > button {
        display: none !important;
      }
    }
  }
  & img{
    width: 100%;
  }
}

.loading {
  font-size: 84px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-align: center;
  span {
    display: inline-block;
    margin: 0 -0.05em;
  }
}

.loading02 {
  & img {
    
  }
}




:global(.lds-ellipsis) {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    animation: loading02 0.8s infinite alternate;
  }
  :global(.lds-ellipsis) div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  :global(.lds-ellipsis) div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  :global(.lds-ellipsis) div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  :global(.lds-ellipsis) div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  :global(.lds-ellipsis) div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

  @keyframes loading02 {
    0% {
      filter: blur(0);
      opacity: 1;
    }
    100% {
      filter: blur(1px);
      opacity: 0.5;
    }
  }